import React from 'react';

const CloseBtn = (props) => (<svg
    onClick={props.clickHandler}
    className="close-btn"
    width="32px"
    height="32px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
        <path
        d="M19 5L4.99998 19M5.00001 5L19 19"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        ></path>
    </g>
</svg>);

export default CloseBtn;