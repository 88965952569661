
import "./style.css";

const RearrangeText = (mainElem, reArrangedText) => {
    if(!reArrangedText || !mainElem) return;

    const textContainer = mainElem.parentNode;
    wrapLettersInsideSpanTag(mainElem);
    let positionDataElem1 = mainElem.cloneNode(true);

    mainElem.classList.add("rearrange-animate");
    positionDataElem1.classList.add("text-position-data", "main-text");
    wrapLettersInsideSpanTag(positionDataElem1);
  

    let positionDataElem2 = document.createElement("p");
    positionDataElem2.classList.add("title", "text-position-data");
    positionDataElem2.innerHTML = reArrangedText;
    wrapLettersInsideSpanTag(positionDataElem2);

    textContainer.appendChild(positionDataElem1);
    textContainer.appendChild(positionDataElem2);

    moveLettersFromSrcToTargetElem(mainElem, positionDataElem1, true);

    let targetElem = null;
    setInterval(() => {
      targetElem = !targetElem || targetElem?.classList.contains("main-text") ? 
        positionDataElem2 : positionDataElem1;
      moveLettersFromSrcToTargetElem(mainElem, targetElem);
    }, 6000);
}

const moveLettersFromSrcToTargetElem = (htmlElemToMove, targetHTMLElem, sameTextNode) => {
    if(!htmlElemToMove || !targetHTMLElem) return;
    const srcLetterElems = htmlElemToMove.querySelectorAll(".letter");
    const targetLetterElems = targetHTMLElem.querySelectorAll(".letter");
  
    if(sameTextNode) {
      for (let k = 0; k < targetLetterElems.length; k++) {
        const targetLetterElem = targetLetterElems[k];
        const srcLetterElem = srcLetterElems[k];

        changeSrcLetterPosition(srcLetterElem, targetLetterElem);
      } 
    }
    else {
      const positionDataElem2Arr = Array.from({ length: targetLetterElems.length});
      for (let i = 0; i < srcLetterElems.length; i++) { 
        const srcLetterElem = srcLetterElems[i];
        const srcLetter = srcLetterElem.innerHTML;

        if(i % 2 === 0) {
          const duration = Math.floor(1 + Math.random() * 4);
          srcLetterElem.style.opacity = 0.3;
          srcLetterElem.style.transitionDuration = `${duration}s`;
          srcLetterElem.style.transitionProperty = `left, top, color, opacity`;
        };
        
        if(!srcLetter || srcLetter === " ") continue;

        for (let j = 0; j < targetLetterElems.length; j++) { 
          const targetLetterElem = targetLetterElems[j];
          const tarLetter = targetLetterElem.innerHTML;

          if(positionDataElem2Arr[j] || srcLetter !== tarLetter) continue;
          positionDataElem2Arr[j] = true;

          changeSrcLetterPosition(srcLetterElem, targetLetterElem);
          srcLetterElem.style.opacity = 1;
          break;
        }
      
      }
    }
}

function changeSrcLetterPosition(srcLetterElem, targetLetterElem) {
  const tarRect = targetLetterElem.getBoundingClientRect();

  const leftPosition = tarRect.left - targetLetterElem.parentNode.getBoundingClientRect().left;
  const topPosition = tarRect.top - targetLetterElem.parentNode.getBoundingClientRect().top;
  
  srcLetterElem.style.top = topPosition + 'px';
  srcLetterElem.style.left = leftPosition + 'px';
}

function wrapLettersInsideSpanTag(container) {
    const originalText = container.textContent;

    const lettersArray = originalText.split('');

    let transformedHTML = '';

    // Iterate through the letters array and wrap each letter in a <span> tag
    lettersArray.forEach(letter => {
      transformedHTML += `<span class="letter">${letter}</span>`;
    });

    container.innerHTML = transformedHTML;
}

export default RearrangeText;