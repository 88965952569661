import React, { useEffect, useRef } from 'react';
import CloudyFog from '../../Assets/Animations/CloudyFog';
import RearrangeText from '../../Assets/Animations/RearrangeText';
import "./style.css";

const Home = props => {
    const nameRef = useRef();

    useEffect(() => {
        RearrangeText(nameRef.current, "I AM VIGNESH");
    }, []);

    return <div className="main-body container">
        <section className="landing-page container">
            {/* <div className="title">
                <div className="bg-text">
                    SHAV IMIGNE
                </div>
                <div className="midPosition">
                    <div class="symbol">
                        <div class="deathly"></div>
                        <div class="hallows"></div>
                    </div>
                    <p className="text">After all this time?..Always!</p>
                </div>
            </div> */}
            <p className="title" ref={nameRef}>
                SHAV IMIGNE
            </p>
            <div className="midPosition">
                <div class="symbol">
                    <div class="deathly"></div>
                    <div class="hallows"></div>
                </div>
                <p className="text">After all this time?..Always!</p>
            </div>
            <CloudyFog />
        </section>
    </div>
};

export default Home;