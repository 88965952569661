import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import BurgerIcon from '../../Assets/Images/BurgerIcon';
import CloseBtn from '../../Assets/Images/CloseBtn';
import "./index.css";

const Header = props => {
    const location = useLocation();
    const [ menuOpen, setMenuOpen ] = React.useState(false);
    const menus = [
        { path: "/", label: "Home" },
        { path: "/about", label: "About Me" },
    ];

    useEffect(() => {
        if(menuOpen)
            setMenuOpen(false);
    }, [location]);

    return <nav >
        <div className="wrapper">
            <div className="logo"></div>
            <div className="menu">
                {!menuOpen ? <BurgerIcon clickHandler={() => setMenuOpen(true)}/> :
                    <CloseBtn clickHandler={() => setMenuOpen(false)}/>}
                <ul className={`list-items ${menuOpen ? "active" : ""}`}>
                    { menus.map(item => 
                        <NavLink 
                            key={item.key}
                            to={item.path} 
                            className={`list-item ${({ isActive }) => isActive ? "active" : ""}`}>
                                {item.label}
                        </NavLink>
                    )}
                </ul>
            </div>
        </div>
    </nav>;
};

export default Header;