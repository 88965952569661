import React from 'react';
import "./style.css";

// require("../../Images/fog-cloud.png")
const CloudyFog = props => {
    // return <>{
    //     Array.from({ length: props.quantity || 4 }).map((_, index) => {
    //         return <img key={index} src={require("../../Images/fog-cloud.png")} className="fog"/>
    //         //return <span key={index}  className="fog"></span>
    //     })
    // }</>
    return <>
        <img src={require("../../Images/fog-cloud.png")} className="fog"/>
        <img src={require("../../Images/fog-cloud.png")} className="fog"/>
        <img src={require("../../Images/fog-cloud.png")} className="fog"/>
        {/* <img src={require("../../Images/fog-cloud.png")} className="fog"/>
        <img src={require("../../Images/fog-cloud.png")} className="fog"/>
        <img src={require("../../Images/fog-cloud.png")} className="fog"/> */}
    </>
};

export default CloudyFog;