import React from 'react';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import {
    HashRouter,
    Routes, // instead of "Switch"
    Route,
  } from "react-router-dom";
import Layout from './components/Layout';

const App = props => {

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<AboutMe />} />
                </Route>
            </Routes>
        </HashRouter>
    );
};

export default App;

