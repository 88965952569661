import React from 'react';
import './index.css';
import dailyProphetGif from '../../Assets/Images/dailyProphet.gif';
import dpLogo from '../../Assets/Images/dp_logo.png';
import exclusiveImage from '../../Assets/Images/EXCLUSIVE.png';


function AboutMe() {
  return (
    <div className="newspaper" >
      <div className = "newsHeader">
        <img src= {dpLogo} alt="dpLogo" className="dpLogo" />
        <hr className="line" />
        <hr className="line" />
        <h1 className="heading">THE DAILY <span className="yellowP">P</span>ROPHET </h1>
        <h4 className="headingSubText"> THE WIZARD WORLD'S BUGUILING BROADSHEET OF CHOICE </h4>
        <hr className="line" />
        <hr className="line" />
      </div>
      
      <div className='mainNews'>
        <h1 className='newsHeadline'>SHAV CHARMS WITH HIS EYES! WANDS DEPRECATED!</h1>
        <div className='mainNewsContent'>
          <div className = "prophetImage">
            <hr className="line" />
            <hr className="line" />
            <img src= {dailyProphetGif} alt="Daily Prophet GIF" />
          </div>
        
          <div className='newsText'>
            <article className='articleNews'>
            <span className='prophetText'>SHAV IMIGNE</span>,
            presumably considered as the world's best wizard of all time (after Albus Dumbledore), has been in hiding (or trying to) amidst 
            the muggle world for the past several years. According to known sources, he has been trying to learn muggle arts 
            like musical instruments, something known as "coding" and merlin knows what else. More shockingly, he's attempting to master them
            in the way muggles do them i.e. WITHOUT MAGIC. It's strange why he'd be interested in making videos in preposterous platforms when
            he can simply save those memories using a Pensieve. Absurdity reaches a new height when sources report him writing
            JOKES so that muggles can laugh. I'd rather have someone use 'Rictumsempra' on me than deliberately pay galleons
            to listen to someone say jokes just so that I can laugh! It's no secret that living like muggles is as arduous as brewing a 
            'Draught of living death' which implies that Shav seems to be in a exploring frame of mind and why not? After all, He who must not
            be named can be named now. Who knows, maybe he's devising an advanced verision of 'Geminio' to replace his eyes as his wand. After all,
            don't you feel charmed just by looking at him?
            </article>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AboutMe;
